<script setup lang="ts">
import { ModalContent } from '@/interfaces';

const { t } = useI18n();

const emit = defineEmits<{
  (e: 'submitHandler'): void;
  (e: 'cancelHandler'): void;
}>();

defineProps<{
  submitName: ModalContent['submitName'];
  cancelName: ModalContent['cancelName'];
  pending: boolean;
  url: string | undefined;
}>();
</script>

<template>
  <div class="modal-actions flex flex-je text-sm">
    <common-button v-if="cancelName" :text="cancelName" class-mods="btn-accent btn-fit"
      @click="emit('cancelHandler')"></common-button>
    <common-button v-if="!pending && !url" :text="submitName || ''" class-mods="btn-accent btn-fit"
      @click="emit('submitHandler')"></common-button>
    <common-button v-if="pending && !url" class-mods="btn-accent btn-fit"
      :text="t('components.common.processing')"></common-button>
    <div v-if="url" class="hrefPaid">
      <a :href="url" target="_blank">{{ t('pages.confirm.paymentGo') }}</a>
    </div>
  </div>
</template>

<style scoped lang="scss">
.hrefPaid {
  align-items: center;
  background-color: green;
  border: 1px solid transparent;
  border-radius: .375rem;
  color: #fff;
  justify-content: center;
  min-height: 40px;
  text-align: center;
  display: flex;
  font-weight: 500;
  padding-right: 10px;
  padding-left: 10px;
}

.modal-actions {
  @apply gap-3;
}
</style>
